import Swiper, { Autoplay, Pagination } from 'swiper';
Swiper.use([Autoplay, Pagination]);

export function swiper() {
  const swiper = new Swiper('.mySwiper', {
    loop: true,
    autoplay: {
      delay: 5200,
      disableOnInteraction: true,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });
}
